import React, { Fragment } from 'react';
import { graphql } from "gatsby"
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Portfolio/portfolio.style';

import Navbar from 'common/src/containers/Portfolio/Navbar';
import Footer from 'common/src/containers/Portfolio/Footer';
import SEO from '../../components/seo';
import ComponentRenderer from '../../components/component-renderer';

export default ({ data }) => {
  const { components, hideMenu } = data.datoCmsLandingPage;

  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <SEO title="We Are Serverless"/>
        <ResetCSS/>
        <GlobalStyle/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar hideMenu={hideMenu} />
            </DrawerProvider>
          </Sticky>
          <ComponentRenderer components={components}/>
          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  )
};

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsLandingPage(slug: { eq: $slug }) {
      hideMenu
      components {
        ... on DatoCmsComponentBanner {
          internal {
            type
          }
          preTagline
          tagline
          subtext
          image {
            url
          }
        }
        ... on DatoCmsComponentHtmlBlock {
          internal {
            type
          }
          text
        }
        ... on DatoCmsComponentStageBlock {
          internal {
            type
          }
          title
          description
        }
        ... on DatoCmsComponentQuadImageBlock {
          internal {
            type
          }
          title
          text
          vendors {
            vendorName
            logo {
              url
            }
            landingPage {
              slug
            }
          }
        }
        ... on DatoCmsComponentFeaturedArticle {
          internal {
            type
          }
          articleType
          title
          description
          url
          image {
            url
          }
        }
        ... on DatoCmsComponentCardsBlock {
          internal {
            type
          }
          title
          description     
        }
        ... on DatoCmsComponentCtaBlock {
          internal {
            type
          }
          title
          description
          link        
        }
      }
    }
  }
`;
